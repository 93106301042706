import React from "react"
import GridLayout from "../../layouts/GridLayout"

export default ({
  useGradientBg,
  gridRowDesktop = 2,
  gridRow = 3,
  children,
  carousel,
  ...props
}) => (
  <>
    <GridLayout {...props} padBottom={false}>
      {children}
    </GridLayout>
    {carousel}
    {props.padBottom && <GridLayout padBottom />}
  </>
)

import React, { useContext, useEffect, useState } from "react"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Span } from "../components/styles/TextStyles.styled"
import ArticleCarouselSection from "../sections/index/ArticleCarousel"
import { urlFor } from "../util/content-links"
import PageWrapperContext from "../context/PageWrapperContext"
import fetch from "cross-fetch"
import ArticleLayout from "../layouts/ArticleLayout"
import DatoCmsArticleContent from "../components/DatoCmsArticleContent"

export const BrandsSectionContent = ({ labels, brands, sdg }) => (
  <>
    {brands && brands.length > 0 && (
      <>
        <Span className="mono">{labels.forThisProjectWeHaveUsed}</Span>
        {brands.map(b => (
          <Span className="brand">{b.title}</Span>
        ))}
      </>
    )}

    {sdg && sdg.length > 0 && (
      <>
        <Span className="mono sdg-label">
          {labels.thisProjectContributedToUnsdg}
        </Span>
        {sdg.map(sdg => (
          <img className="sdg" src={sdg.icon.url} alt={sdg.title} />
        ))}
      </>
    )}
  </>
)

export default ({ data: { article, labels } }) => {
  const { setTheme, setHeaderCollapsedTheme, setHeaderTheme } = useContext(
    PageWrapperContext
  )
  useEffect(() => {
    setTheme(PageWrapperContext.default.theme)
    setHeaderCollapsedTheme(null)
    setHeaderTheme(PageWrapperContext.default.theme)
  })

  const id = article.originalId

  const [related, setRelated] = useState([])

  useEffect(() => {
    let read = sessionStorage.getItem("read")
    if (!read) {
      read = article.originalId
    } else {
      read += `,${article.originalId}`
    }
    sessionStorage.setItem("read", read)

    fetch("/.netlify/functions/related", {
      method: "POST",
      body: JSON.stringify({
        post: id,
        locale: article.locale,
        tags: article.categories.map(c => c.originalId),
        previous: read.split(","),
      }),
    })
      .then(res => res.json())
      .then(related => {
        related.forEach(r => (r.locale = article.locale))
        setRelated(related)
      })
  }, [id])
  return (
    <ArticleLayout
      {...article}
      writtenByLabel={labels.articleWrittenBy}
      content={article.content.map(c => ({
        ...c,
        node: <DatoCmsArticleContent {...c} />,
        breakGrid: c.__typename === "DatoCmsSlideshow",
      }))}
      brandsSectionContent={
        <BrandsSectionContent {...article} labels={labels} />
      }
      contact={
        article.contact && {
          title: labels.learnMoreAboutThisProject,
          linkLabel: labels.contact,
          pleaseContactLabel: labels.pleaseContact,
          name: article.contact.name,
          email: article.contact.email,
          role: article.contact.role,
          phoneNumber: article.contact.phoneNumber,
        }
      }
      tags={article.categories}
      tagsLabel={labels.tags}
    >
      <HelmetDatoCms seo={article.seoMetaTags} />
      {related && related.length > 0 && (
        <ArticleCarouselSection
          css={{ marginTop: "100px" }}
          title={labels.relatedProjects}
          link={{
            label: labels.checkThemAllOut,
            url: urlFor(article.__typename, article.locale),
            external: false,
          }}
          articles={related}
          plainBg={true}
        />
      )}
    </ArticleLayout>
  )
}

export const query = graphql`
  query($id: String!, $locale: String!) {
    labels: datoCmsTranslation(locale: { eq: $locale }) {
      back
      articleWrittenBy
      forThisProjectWeHaveUsed
      thisProjectContributedToUnsdg
      relatedProjects
      checkThemAllOut
      learnMoreAboutThisProject
      pleaseContact
      contact
      tags
    }
    article: datoCmsArticle(id: { eq: $id }, locale: { eq: $locale }) {
      __typename
      originalId
      actualPublishDate
      locale
      title
      clientName
      clientLogo {
        url
      }

      author {
        __typename
        ... on DatoCmsExternal {
          email
          name
          isAvatarRound
          image {
            ...SmallAvatar
          }
        }
        ... on DatoCmsTeamMember {
          email
          phoneNumber
          name
          isAvatarRound
          image {
            ...SmallAvatar
          }
        }
      }
      contact {
        email
        phoneNumber
        name
        role
      }
      content {
        ... on DatoCmsText {
          __typename
          id
          text
          textNode {
            internal {
              mediaType
            }
          }
        }
        ... on DatoCmsImage {
          id
          captionVisible
          image {
            alt
            title
            fluid(maxWidth: 900, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
        ... on DatoCmsDownload {
          id
          file {
            url
            title
            filename
          }
        }
        ... on DatoCmsCallToActionBlock {
          ...CTABlockData
        }
        ... on DatoCmsSlideshow {
          id
          images {
            fluid(
              maxWidth: 1440
              imgixParams: { fm: "jpg", auto: "compress" }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
        ... on DatoCmsAdditionalContent {
          id
          title
          text
          textNode {
            internal {
              mediaType
            }
          }
        }
      }
      brands {
        title
      }
      categories {
        ... on DatoCmsArticleCategory {
          locale
          name
          excerpt
          tagType
          originalId
        }
        ... on DatoCmsBrand {
          id
          title
          shortName
          originalId
        }
      }
      sdg {
        title
        icon {
          url
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`

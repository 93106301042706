import React, { useContext } from "react"
import LinkedSectionTitle from "../../components/SectionTitles/LinkedSectionTitle"
import CarouselSection from "../generic/CarouselSection"
import Carousel from "../../components/Carousel"
import { urlFor } from "../../util/content-links"
import { DomainContext } from "../../context/DomainContext"

const ArticleCarousel = ({ title, cta, link, articles, plainBg, ...props }) => {
  const domainContext = useContext(DomainContext)
  let slides = articles.map(article => ({
    title: article.title,
    description: article.seo.description,
    url: urlFor(
      article.__typename,
      article.locale,
      article.slug,
      "",
      domainContext
    ),
    author: article.author,
    fluid: article.seo.image.fluid,
  }))

  return (
    <CarouselSection
      useGradientBg={!plainBg}
      {...props}
      carousel={<Carousel type="articles" items={slides} />}
    >
      <LinkedSectionTitle title={title} link={link} cta={cta} />
    </CarouselSection>
  )
}

export default ArticleCarousel
